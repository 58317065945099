/* eslint-disable */
// THIS FILE IS AUTO-GENERATED!
import React from 'react';
import { Switch, LoadableRoute } from '@e4/ebanking-web-utils';

const routes = (
  <Switch>
    <LoadableRoute
      path="(/sv)?/menu"
      key="(/sv)?/menu"
      
      loader={() => import('@e4/ebanking-web-menu')}
    />
<LoadableRoute
      path="(/sv)?/logoff"
      key="(/sv)?/logoff"
      
      loader={() => import('@e4/ebanking-web-logoff')}
    />
<LoadableRoute
      path="(/sv)?/odd"
      key="(/sv)?/odd"
      
      loader={() => import('@e4/ebanking-web-odd')}
    />
<LoadableRoute
      path="(/sv)?/fia"
      key="(/sv)?/fia"
      
      loader={() => import('@e4/ebanking-web-fia')}
    />
<LoadableRoute
      path="(/sv)?/consumerloan"
      key="(/sv)?/consumerloan"
      
      loader={() => import('@e4/ebanking-web-consumerloan')}
    />
<LoadableRoute
      path="(/sv)?/signing"
      key="(/sv)?/signing"
      
      loader={() => import('@e4/ebanking-web-signing')}
    />
<LoadableRoute
      path="(/sv)?/investment/continuoussavings"
      key="(/sv)?/investment/continuoussavings"
      
      loader={() => import('@e4/ebanking-web-investment-continuous-savings')}
    />
  </Switch>
);

export default routes;
